'use client';

import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import { useRouter } from 'next/navigation';
import { signOut, useSession } from 'next-auth/react';
import { ArrowsOutSimple, Close, Minus } from '@/components/icons';
import { ROUTES } from '@/config/links';
import cn from '@/utils/cn';
interface WidgetHeaderProps {
  onClose: () => void;
}
const WidgetHeader_1 = _compiledBlock(_props => <div className={_props.v0}>
      <div className={_props.v1}>
        <div className={_props.v2}>
          <div className={_props.v3} onClick={_props.v4}>
            {_props.v5}
          </div>
          <div className={_props.v6} onClick={_props.v7}>
            {_props.v8}
          </div>
          <div className={_props.v9} onClick={_props.v10}>
            {_props.v11}
          </div>
        </div>
        <h4>Guestbook</h4>
      </div>
      {_props.v12}
    </div>, {
  name: "WidgetHeader_1",
  portals: ["v5", "v8", "v11", "v12"]
});
const WidgetHeader = ({
  onClose
}: WidgetHeaderProps) => {
  const {
    data: session
  } = useSession();
  const router = useRouter();
  return /*@million jsx-skip*/<WidgetHeader_1 v0={cn('flex items-center justify-between border-muted px-4 py-3 font-medium')} v1={cn('flex items-center gap-4')} v2={cn('flex gap-2')} v3={cn('group flex h-3.5 w-3.5 cursor-pointer items-center justify-center rounded-full bg-[#FF605C] text-neutral-800')} v4={onClose} v5={<Close className={cn('hidden h-2 w-2', 'group-hover:flex')} />} v6={cn('group flex h-3.5 w-3.5 cursor-pointer items-center justify-center rounded-full bg-[#FFBD44] text-neutral-800')} v7={onClose} v8={<Minus className={cn('hidden h-2 w-2', 'group-hover:flex')} />} v9={cn('group flex h-3.5 w-3.5 cursor-pointer items-center justify-center rounded-full bg-[#00CA4E] text-neutral-800')} v10={() => {
    onClose();
    router.push(ROUTES.guestbook);
  }} v11={<ArrowsOutSimple className={cn('hidden h-2 w-2', 'group-hover:flex')} />} v12={session && <div className={cn('cursor-pointer text-sm font-semibold underline')} onClick={async () => {
    await signOut();
  }}>
          Logout
        </div>} />;
};
export default WidgetHeader;