import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"node_modules/geist/dist/mono.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-mono/GeistMono-Variable.woff2\",\"variable\":\"--font-geist-mono\",\"adjustFontFallback\":false,\"fallback\":[\"ui-monospace\",\"SFMono-Regular\",\"Roboto Mono\",\"Menlo\",\"Monaco\",\"Liberation Mono\",\"DejaVu Sans Mono\",\"Courier New\",\"monospace\"],\"weight\":\"100 900\"}],\"variableName\":\"GeistMono\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../assets/fonts/CalSans-SemiBold.woff2\",\"variable\":\"--font-cal\"}],\"variableName\":\"fontCal\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/guestbook/widget-button.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/analytics.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/header.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/now-playing/now-playing.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/providers.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/back-button.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/counter.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/increment-counter.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/progress.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/tooltip.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/global.css")